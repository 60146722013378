// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-md": () => import("/opt/build/repo/src/pages/about.md" /* webpackChunkName: "component---src-pages-about-md" */),
  "component---src-pages-index-md": () => import("/opt/build/repo/src/pages/index.md" /* webpackChunkName: "component---src-pages-index-md" */),
  "component---src-pages-nybbles-compilers-md": () => import("/opt/build/repo/src/pages/nybbles/compilers.md" /* webpackChunkName: "component---src-pages-nybbles-compilers-md" */),
  "component---src-pages-nybbles-terraform-md": () => import("/opt/build/repo/src/pages/nybbles/terraform.md" /* webpackChunkName: "component---src-pages-nybbles-terraform-md" */),
  "component---src-pages-nybbles-testing-md": () => import("/opt/build/repo/src/pages/nybbles/testing.md" /* webpackChunkName: "component---src-pages-nybbles-testing-md" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

